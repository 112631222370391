<template>
  <div class="textDetails">
    <div v-if="loading || !text">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
            <b-col class="mt-5">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-bind:title="text.sigleLegam" :pretitle="this.$t('TextDetails')" />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode && config.editable">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button v-if="$store.getters.checkRole('legam.bib.text.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.bib.text.modify')" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button
                  v-if="!$store.getters.checkRole('legam.bib.text.modify') && $store.getters.checkRole('legam.bib.text.delete')"
                  @click="deleteItem"
                  class="btn-legam-xs mb-2 btn-danger"
                >
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>

        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <div class="mb-4" v-if="config.editable">
                <p class="mb-1">BIB Labels</p>
                <BibLabelSelect v-if="editMode" :selected="text.biblabels.map(biblabel => biblabel.id)" @select="selectBibLabels" />
                <span v-else>
                  <b-badge v-for="biblabel in text.biblabels" :key="biblabel.id" href="#" class="mr-1" variant="secondary">{{ biblabel.label }}</b-badge>
                </span>
              </div>
              <div class="mb-4">
                <p class="mb-1">{{ $t('Titles') }}</p>
                <TitleSelect v-if="editMode" :selected="text.titles" @select="selectTitle" />
                <span v-else>
                  <h3 v-if="this.text.titles.length" v-html="this.text.titles[0].titleName"></h3>
                  <h5 v-if="this.text.titles.length > 1" v-html="this.text.titles.slice(1).map(title => title.titleName).join(', ')"></h5>
                </span>
              </div>
              <b-row>
                <b-col>
                  <h6>{{ $t('SigleLEGaM') }}</h6>
                  <b-form-input
                    id="sigle-legam"
                    v-model="text.sigleLegam"
                    placeholder="Sigle LEGaM"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="text.sigleLegam"></span>
                </b-col>
                <b-col>
                  <h6>{{ $t('Genre') }}</h6>
                  <Select2 v-if="editMode" v-model="text.genreId" :options="genreOptions" @select="selectGenre($event)" :settings="{}" />
                  <span v-else>
                    <span v-if="text.genre" v-html="text.genre.display"></span>
                  </span>
                </b-col>
                <b-col>
                  <h6>{{ $t('MetaText') }}</h6>
                  <Select2
                    v-if="editMode"
                    v-model="text.metatextId"
                    :options="metatextOptions"
                    @select="selectMetatext($event)"
                    :settings="{}" />
                  <span v-else>
                    <span v-if="text.metatext" v-html="text.metatext.title"></span>
                  </span>
                </b-col>
                <!-- <b-col>
                  <h6>Alternative Sigles</h6>
                  <div
                    v-for="siglesource in text.siglesources"
                    :key="siglesource.siglesourceCode"
                    v-html="`${siglesource.alternativeSigle} - ${siglesource.siglesourceCode}`"
                  ></div>
                </b-col> -->
              </b-row>
              <div class="legam-details-box mt-3">
                  <font v-if="locManuscript" style="color:gray">Localization shared  with <font style="color:green">{{ locManuscript }}</font></font>
                  <b-row class="mt-3">
                    <b-col>
                      <h6>{{ $t('Scriptae') }}</h6>
                      <!-- <Select2 v-if="editMode" v-model="text.localizationId" :options="localizationOptions" @select="selectLocalization($event)" :settings="{}" /> -->
                      <ScriptaSelect
                        v-if="editMode"
                        :selected="text.localization ? text.localization.scriptas : []"
                        @select="updateScripta"
                      />
                      <span v-else>
                        <span
                          v-if="text.localization && text.localization.scriptas"
                          v-html="text.localization.scriptas.map(scripta => scripta.code).join(', ')"
                        ></span>
                      </span>
                    </b-col>
                    <b-col>
                      <h6>{{ $t('Locations') }}</h6>
                      <LocationSelectMulti
                        v-if="editMode"
                        :selected="text.localization ? text.localization.locations : []"
                        :allowMultiple="true"
                        @select="updateLocation"
                      />
                      <span v-else>
                        <span
                          v-if="text.localization && text.localization.locations"
                          v-html="text.localization.locations.map(location => location.display).join(', ')"
                        ></span>
                      </span>
                    </b-col>
                    <b-col>
                      <h6>{{ $t('Scriptoria') }}</h6>
                      <!-- <ScriptoriumSelect
                        v-if="editMode"
                        @select="updateScriptorium"
                        :selected="text.localization ? text.localization.scriptoriums : []"
                      /> -->
                        <span
                          v-if="text.localization && text.localization.scriptoriums"
                          v-html="text.localization.scriptoriums.map(scriptorium => scriptoriumString(scriptorium)).join('<br>')"
                        ></span>
                    </b-col>
                  </b-row>
              </div>
              <b-row class="mt-3">
                <b-col>
                  <h6>Date</h6>
                  <b-form-input
                    id="dates"
                    v-model="text.dateSpec"
                    placeholder="Date Spec"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="text.dateSpec"></span>
                </b-col>
                <b-col>
                  <h6>Date Num</h6>
                  <b-form-input
                    id="dateNum"
                    v-model="text.dateNum"
                    placeholder="Date Num"
                    v-if="editMode"
                    type="date"
                  ></b-form-input>
                  <span v-else v-html="text.dateNum"></span>
                </b-col>
                <b-col>
                  <h6>{{ $t('DiscourseModality') }}</h6>
                  <b-form-input
                    id="discourseModality"
                    v-model="text.discourseModality"
                    placeholder="Discourse Modality"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="text.discourseModality"></span>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <h6>{{ $t('CommentScripta') }}</h6>
                  <b-form-textarea
                    id="comment-scripta"
                    placeholder="Comment Scripta"
                    v-model="text.commentScripta"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else class="white-space-pre-line" v-html="text.commentScripta"></span>
                </b-col>
                <b-col>
                  <h6>{{ $t('CommentGeneral') }}</h6>
                  <b-form-textarea
                    id="comment-general"
                    placeholder="Comment General"
                    v-model="text.commentGeneral"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else class="white-space-pre-line" v-html="text.commentGeneral"></span>
                </b-col>
                <b-col>
                  <h6>{{ $t('References') }}</h6>
                  <b-form-textarea
                    id="reference"
                    placeholder="Reference"
                    v-model="text.reference"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else v-html="text.reference"></span>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <h6>{{ $t('PlaceOfIssue') }}</h6>
                  <b-form-textarea
                    id="place-of-issue"
                    placeholder="Place of Issue"
                    v-model="text.placeOfIssue"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else class="white-space-pre-line" v-html="text.placeOfIssue"></span>
                </b-col>
                <b-col>
                </b-col>
                <b-col>
                </b-col>
              </b-row>
              <hr>

              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 class="text-left">
                      Manuscripts <small>({{ text.manuscripts.length }} {{ text.manuscripts.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-manuscript>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-manuscript" title="Add manuscript" ok-only ok-variant="secondary" @ok="addManuscript" size="lg">
                        <ManuscriptAutocompleteText :manuscript="modalManuscript" />
                      </b-modal>
                      <ManuscriptTable
                        :showTotal="false"
                        :showTextManuscript="true"
                        :manuscripts="text.manuscripts"
                        @delete="deleteManuscript"
                        @edit="editManuscript"
                        :editMode="editMode"
                        v-if="text.manuscripts.length"
                        :key="indexManuscript"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 class="text-left">
                      Editions <small>({{ text.editions.length }} {{ text.editions.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-3" accordion="my-accordion-3" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-edition>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-edition" title="Add edition" ok-only ok-variant="secondary" @ok="addEdition" size="lg">
                        <EditionAutocompleteText :edition="modalEdition" />
                      </b-modal>
                      <EditionTable
                        :showTotal="false"
                        :editions="text.editions"
                        :showEditionText="true"
                        @delete="deleteEdition"
                        @edit="editEdition"
                        :editMode="editMode"
                        v-if="text.editions.length"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-4 class="text-left">
                      Studies <small>({{ text.studies.length }} {{ text.studies.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-4" accordion="my-accordion-4" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-study>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-study" title="Add study" ok-only ok-variant="secondary" size="lg">
                        <StudyAutocomplete @select="addStudy" />
                      </b-modal>
                      <StudyTable
                        :showTotal="false"
                        :studies="text.studies"
                        @delete="deleteStudy"
                        :editMode="editMode"
                        v-if="text.studies.length"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-5 class="text-left">
                      Historical Authors <small>({{ text.authorhists.length }} {{ text.authorhists.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-5" accordion="my-accordion-5" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-authorhist>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-authorhist" title="Add historical author" ok-only ok-variant="secondary" size="lg">
                        <AuthorhistAutocomplete @select="addAuthorhist" />
                      </b-modal>
                      <AuthorhistTable
                        :showTotal="false"
                        :authorhists="text.authorhists"
                        @delete="deleteAuthorhist"
                        :editMode="editMode"
                        v-if="text.authorhists.length"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-6 class="text-left">
                      Alternative Sigles <small>({{ text.siglesources.length }} {{ text.siglesources.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-6" accordion="my-accordion-6" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-siglesource>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-siglesource" title="Add alternative sigle" ok-only ok-variant="secondary" @ok="addSigleSource" size="lg">
                        <SigleSourceRelAutocomplete :sigleSourceRel="modalSigleSourceRel" />
                      </b-modal>
                      <SigleSourceRelTable
                        :showTotal="false"
                        :siglesourcesRel="text.siglesources"
                        @delete="deleteSigleSource"
                        @edit="editSigleSource"
                        :editMode="editMode"
                        v-if="text.siglesources.length"
                        :key="indexSigleSource"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-7 class="text-left">
                      Links <small>({{ text.links.length }} {{ text.links.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-7" accordion="my-accordion-7" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-link>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-link" title="Add link" ok-only ok-variant="secondary" @ok="addLink" size="lg">
                        <LinkAutocomplete :link="modalLink" />
                      </b-modal>
                      <LinkTable
                        :showTotal="false"
                        :links="text.links"
                        @delete="deleteLink"
                        @edit="editLink"
                        :editMode="editMode"
                        v-if="text.links.length"
                        :key="indexLink"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-8 class="text-left">
                      Scriptoria <small>({{ text.localization ? text.localization.scriptoriums.length : 0 }} {{ text.localization && text.localization.scriptoriums.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-8" accordion="my-accordion-8" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-scriptorium>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-scriptorium" title="Add scriptorium" ok-only ok-variant="secondary" @ok="addScriptorium" size="lg">
                        <ScriptoriumAutocompleteManuscriptText :scriptorium="modalScriptorium" />
                      </b-modal>
                      <ScriptoriumTable
                        :showTotal="false"
                        :scriptoriums="text.localization.scriptoriums"
                        @delete="deleteScriptorium"
                        @edit="editScriptorium"
                        :editMode="editMode"
                        v-if="text.localization && text.localization.scriptoriums.length"
                        :key="indexLink"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </article>
          </b-col>
        </b-row>
        <div class="mt-4" v-if="config.editable">
          <h6>Logs</h6>
          <ChangeLogsTable :objectType="'text'" :objectId="textId" :key="'logs' + index" />
        </div>
      </b-container>
    </div>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import ManuscriptTable from '@/components/Bibliography/ManuscriptTable.vue'
  import EditionTable from '@/components/Bibliography/EditionTable.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import StudyTable from '@/components/Bibliography/StudyTable.vue'
  import TitleSelect from '@/components/Bibliography/TitleSelect.vue'
  import AuthorhistTable from '@/components/Bibliography/AuthorhistTable.vue'
  import LinkTable from '@/components/Bibliography/LinkTable.vue'
  import ManuscriptAutocompleteText from '@/components/Bibliography/ManuscriptAutocompleteText.vue'
  import EditionAutocompleteText from '@/components/Bibliography/EditionAutocompleteText.vue'
  import StudyAutocomplete from '@/components/Bibliography/StudyAutocomplete.vue'
  import SigleSourceRelAutocomplete from '@/components/Bibliography/SigleSourceRelAutocomplete.vue'
  import SigleSourceRelTable from '@/components/Bibliography/SigleSourceRelTable.vue'
  import AuthorhistAutocomplete from '@/components/Bibliography/AuthorhistAutocomplete.vue'
  import LinkAutocomplete from '@/components/Bibliography/LinkAutocomplete.vue'
  import BibLabelSelect from '@/components/Bibliography/BibLabelSelect.vue'
  // import ScriptoriumSelect from '@/components/Bibliography/ScriptoriumSelect.vue'
  import ScriptaSelect from '@/components/Main/ScriptaSelect.vue'
  import LocationSelectMulti from '@/components/Main/LocationSelectMulti.vue'
  import ScriptoriumTable from '@/components/Bibliography/ScriptoriumTable.vue'
  import ScriptoriumAutocompleteManuscriptText from '@/components/Bibliography/ScriptoriumAutocompleteManuscriptText.vue'
  import Select2 from 'v-select2-component'
  import HelpStore from '@/store/helpstore.js'
  import Utils from '@/utils.js'
  import config from '@/config.js'

  export default {
    name: 'TextDetails',
    data() {
      return {
        config: config,
        textId: this.$route.params.id,
        loading: true,
        editMode: false,
        index: 0,
        indexManuscript: 0,
        modalManuscript: null,
        indexEdition: 0,
        modalEdition: null,
        modalScriptorium: null,
        indexSigleSource: 0,
        modalSigleSourceRel: null,
        indexLink: 0,
        modalLink: null,
        locManuscript: null
      }
    },
    components: {
      PageTitle,
      ManuscriptTable,
      EditionTable,
      StudyTable,
      AuthorhistTable,
      LinkTable,
      ManuscriptAutocompleteText,
      EditionAutocompleteText,
      StudyAutocomplete,
      AuthorhistAutocomplete,
      LinkAutocomplete,
      Select2,
      TitleSelect,
      ChangeLogsTable,
      BibLabelSelect,
      // ScriptoriumSelect,
      ScriptoriumTable,
      ScriptoriumAutocompleteManuscriptText,
      ScriptaSelect,
      LocationSelectMulti,
      SigleSourceRelAutocomplete,
      SigleSourceRelTable,
    },
    computed: {
      ...mapState({
        text: state => state.text.text,
        genres: state => state.common.genresAll,
        localizations: state => state.common.localizations,
        metatexts: state => state.common.metatexts
      }),
      genreOptions(){
        return this.genres.map(genre => {
          return {
            id: genre.id,
            text: genre.display
          }
        }).sort((a, b) => (a.text > b.text) ? 1 : -1)
      },
      metatextOptions() {
        return this.metatexts.map(metatext => {
          return {
            id: metatext.id,
            text: metatext.title
          }
        }).sort((a, b) => (a.text > b.text) ? 1 : -1)
      },
      localizationOptions(){
        return this.localizations.map(localization => {
          return {
            id: localization.id,
            text: localization.display
          }
        }).sort((a, b) => (a.text > b.text) ? 1 : -1)
      }
    },
    mounted(){
      this.$store.dispatch('loadText', this.textId)
      if (this.config.editable) {
        this.$store.dispatch('loadGenres')
        this.$store.dispatch('loadLocalizations')
        this.$store.dispatch('loadMetatexts')
      }
    },
    updated(){
      let man = this.text.manuscripts.filter(
        manuscript => manuscript.transmissionMode  == 'or.' ||
                      manuscript.transmissionMode  == 'cp.-id.' ||
                      manuscript.transmissionMode  == 'vid.'
      )[0]
      this.locManuscript = man ? `${man.codeRepcrit} [${man.transmissionMode}]` : null
    },
    watch: {
      'textId'(){
        this.loading = true
        this.$store.dispatch('loadText', this.textId)
      },
      'text'(){
        this.loading = false
      }
    },
    methods: {
      selectGenre({id, text}){
        this.text.genreId = id
        this.text['genre'] = {} || this.text.genre
        this.text.genre['id'] = id
        this.text.genre['display'] = text
      },
      selectMetatext({id, text}){
        this.text.metatextId = id
        this.text['metatext'] = {} || this.text.metatext
        this.text.metatext['id'] = id
        this.text.metatext['title'] = text
      },
      selectLocalization({id, text}){
        this.text.localizationId = id
        this.text['localization'] = {} || this.text.localization
        this.text.localization['id'] = id
        this.text.localization.display = text
      },
      selectTitle(titles){
        this.text['titles'] = titles
      },
      selectBibLabels(biblabels){
        this.text['biblabels'] = biblabels
      },
      updateScriptorium(scriptoriums) {
        if (!this.text.localization){
          this.text.localization = {
            scriptoriums: null,
            scriptas: null,
            locations: null,
          }
        }
        this.text.localization.scriptoriums = scriptoriums
      },
      updateScripta(scriptas) {
        if (!this.text.localization){
          this.text.localization = {
            scriptoriums: null,
            scriptas: null,
            locations: null,
          }
        }
        this.text.localization.scriptas = scriptas
      },
      updateLocation(locations) {
        if (!this.text.localization){
          this.text.localization = {
            scriptoriums: null,
            scriptas: null,
            locations: null,
          }
        }
        this.text.localization.locations = locations
      },
      addManuscript(){
        this.modalManuscript = null;
        if (HelpStore.item){
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Manuscript added`,
          })
          if (HelpStore.item._index != null){
            this.text.manuscripts[HelpStore.item._index] = HelpStore.item;
          }
          else {
            this.text.manuscripts.push(HelpStore.item);
          }
          this.indexManuscript++;
        }
      },
      editManuscript(manuscript){
        this.modalManuscript = manuscript;
        this.$bvModal.show('modal-add-manuscript');
      },
      deleteManuscript(manuscriptId){
        this.text.manuscripts = this.text.manuscripts.filter(
          manuscript => manuscript.id != manuscriptId
        )
      },
      addEdition(){
        this.modalEdition = null;
        if (HelpStore.item){
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Edition added`,
          })
          if (HelpStore.item._index != null){
            this.text.editions[HelpStore.item._index] = HelpStore.item;
          }
          else {
            this.text.editions.push(HelpStore.item);
          }
          this.indexEdition++;
        }
      },
      deleteEdition(editionId){
        this.text.editions = this.text.editions.filter(edition => edition.id != editionId)
      },
      editEdition(edition){
        this.modalEdition = edition;
        this.$bvModal.show('modal-add-edition');
      },
      addSigleSource(){
        this.modalSigleSourceRel = null;
        if (HelpStore.item){
          if (HelpStore.item._index != null){
            this.text.siglesources[HelpStore.item._index] = HelpStore.item;
          }
          else {
            this.text.siglesources.push(HelpStore.item);
          }
          this.indexSigleSource++;
        }
      },
      deleteSigleSource(sigleSourceRelId){
        this.text.siglesources = this.text.siglesources.filter(sigleSourceRel => sigleSourceRel.id != sigleSourceRelId)
      },
      editSigleSource(sigleSourceRel){
        this.modalSigleSourceRel = sigleSourceRel;
        this.$bvModal.show('modal-add-siglesource');
      },
      addStudy(study){
        this.$bvModal.hide('modal-add-study')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Study added`
        })
        this.text.studies.push(study)
      },
      deleteStudy(studyId){
        this.text.studies = this.text.studies.filter(study => study.id != studyId)
      },
      addAuthorhist(authorhist){
        this.$bvModal.hide('modal-add-authorhist')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Authorhist added`
        })
        this.text.authorhists.push(authorhist)
      },
      deleteAuthorhist(authorhistId){
        this.text.authorhists = this.text.authorhists.filter(authorhist => authorhist.id != authorhistId)
      },

      addScriptorium(){
        this.modalScriptorium = null;
        if (HelpStore.item){
          if (HelpStore.item._index != null){
            this.text.localization.scriptoriums[HelpStore.item._index] = HelpStore.item;
          }
          else {
            this.text.localization.scriptoriums.push(HelpStore.item);
          }
          this.indexLink++;
        }
      },
      deleteScriptorium(scriptoriumId){
        this.text.localization.scriptoriums = this.text.localization.scriptoriums.filter(
          scriptorium => scriptorium.id != scriptoriumId
        )
      },
      editScriptorium(scriptorium){
        this.modalScriptorium = scriptorium;
        this.$bvModal.show('modal-add-scriptorium');
      },

      addLink(){
        this.modalLink = null;
        if (HelpStore.item){
          if (HelpStore.item._index != null){
            this.text.links[HelpStore.item._index] = HelpStore.item;
          }
          else {
            this.text.links.push(HelpStore.item);
          }
          this.indexLink++;
        }
      },
      deleteLink(linkId){
        this.text.links = this.text.links.filter(link => link.id != linkId)
      },
      editLink(link){
        this.modalLink = link;
        this.$bvModal.show('modal-add-link');
      },
      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadText', this.textId)
        this.$store.dispatch('addNotification', {
          type: 'warning',
          text: `Changes dismissed`
        })
      },
      saveEdit(){
        this.editMode = false
        this.$store.dispatch('saveText', this.text).then(() => {
          this.$store.dispatch('loadText', this.textId)
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
          this.index++
        })
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteText', this.text).then(() => {
              router.push('/texts')
            })
          }
        })
      },
      scriptoriumString: Utils.scriptoriumString,
    }
  }
</script>

<template>
  <div class="titles" id="select-title">
    <Select2 @select="select($event)" v-model="selectedItems" :settings="selectConfig" ref="select2" tabindex="0" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'

  export default {
    name: 'TitleSelect',
    props: {
      'exclude': {default: () => []},
      'selected': {default: () => []}
    },
    components: {
      Select2
    },
    data(){
      return {
        selectedItems: this.selected.map(item => item.id),
        selectedObjs: this.selected,
        lastSearch: '',
        newIdIndex: -1
      }
    },
    mounted() {
      if (this.selected){
        this.selected.forEach(title => {
          this.$refs.select2.select2.append(
            `<option selected value="${title.id}">${title.titleName}</option>`
          ).trigger('change')
        })
      }
    },
    computed: {
      selectConfig(){
        return {
          placeholder: "Select a title",
          allowClear: true,
          width: '100%',
          dropdownParent: "#select-title",
          multiple: true,
          ajax: {
            delay: 250,
            url: config.apiUrl + '/titles/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              this.lastSearch = params.term
              return {
                query: params.term
              }
            },
            processResults: (data) => {
              data.push({
                id: this.newIdIndex,
                titleName: `Create new title: ${this.lastSearch}`,
                title: {
                  "id": this.newIdIndex.toString(),
                  "titleName": this.lastSearch
                }
              })
              return {
                results: data.map(title => {
                  return {
                    id: title.id,
                    text: title.titleName,
                    title: title
                  }
                })
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        if (event.selected === true){
          if (event.title.id < 0){
            this.newIdIndex--
            this.selectedObjs.push(event.title.title)
          }
          else {
            this.selectedObjs.push(event.title)
          }
        }
        else if (event.selected == false && event.id) {
          this.selectedObjs = this.selectedObjs.filter(title => title.id != event.id)
        }
        this.$emit('select', this.selectedObjs)
      }
    }
  }
</script>

<template>
  <div class="manuscripts">
    <b-container fluid="xxl">
      <b-row>
        <b-col>
          <ManuscriptSelect
            @select="selectManuscript"
            :manuscript="manuscriptObj"
            :allowClear="false"
            class="mb-3"
          />

          <b-form-group
            id="folios"
            label="Folios:"
            label-for="folios"
          >
            <b-form-input
              id="folios"
              v-model="folios"
              placeholder="Folios"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="foliosNum"
            label="Folios Num:"
            label-for="foliosNum"
          >
            <b-form-input
              id="foliosNum"
              v-model="foliosNum"
              placeholder="Folios Num"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ManuscriptSelect from "@/components/Bibliography/ManuscriptSelect.vue";
import HelpStore from '@/store/helpstore.js'

export default {
  name: "ManuscriptAutocompleteText",
  props: ["exclude", "manuscript"],
  data() {
    return {
      query: "",
      timer: null,
      manuscriptObj: this.manuscript,
      foliosNum: this.manuscript ? this.manuscript.foliosNum : '',
      folios: this.manuscript ? this.manuscript.folios : '',
      index: this.manuscript ? this.manuscript._index : null,
    };
  },
  components: {
    ManuscriptSelect,
  },
  methods: {
    selectManuscript(manuscript) {
      this.manuscriptObj = manuscript;
    },
  },
  updated(){
    this.manuscriptObj['foliosNum'] = this.foliosNum;
    this.manuscriptObj['folios'] = this.folios;
    this.manuscriptObj['_index'] = this.index;
    HelpStore.item = this.manuscriptObj
  }
};
</script>

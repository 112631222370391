<template>
  <div class="editions">
    <b-container fluid="xxl">
      <b-row>
        <b-col>
          <EditionSelect
            @select="select"
            :edition="editionObj"
            :allowClear="false"
            class="mb-3"
          />

          <b-form-group
            id="modern-paging"
            label="Modern paging:"
            label-for="modern-paging"
          >
            <b-form-input
              id="modern-paging"
              v-model="modernPaging"
              placeholder="modern paging"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import EditionSelect from "@/components/Bibliography/EditionSelect.vue";
import HelpStore from "@/store/helpstore.js";

export default {
  name: "EditionAutocompleteText",
  props: ["exclude", "edition"],
  data() {
    return {
      query: "",
      timer: null,
      editionObj: this.edition,
      modernPaging: this.edition ? this.edition.modernPaging : "",
      index: this.edition ? this.edition._index : null,
    };
  },
  components: {
    EditionSelect,
  },
  methods: {
    select(edition) {
      this.editionObj = edition;
    },
  },
  updated() {
    this.editionObj["modernPaging"] = this.modernPaging;
    this.editionObj["_index"] = this.index;
    HelpStore.item = this.editionObj;
  },
};
</script>
